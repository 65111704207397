import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { Button, Skeleton, Popconfirm, Divider, message } from 'antd';
import { grey, red } from '@ant-design/colors';
import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ProductTypeCard } from './components/product_type_card';
import { useProductGroupDetails } from './use_product_group_details';
import {
	EditableImage,
	EditableText,
	IconButton,
	OfferBreadcrumbs,
	ProductType,
	RECOMMENDED_COVER_DIMENSIONS,
	readFile,
} from 'shared';

const Wrapper = styled.div`
	padding: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;

	row-gap: 32px;
`;

const CardList = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	> div {
		width: 100%;
		max-width: 350px;
		margin: 8px 0;
	}
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	max-width: 400px;

	> div {
		&:first-child {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 8px;
			column-gap: 16px;

			> .title > span {
				font-size: 24px;
				font-weight: bold;
			}
		}

		> p {
			margin: 0;
			margin-bottom: 4px;
			font-size: 0.9em;
			color: ${grey[3]};
		}
	}

	> img {
		width: 100%;
		max-width: 400px;
		aspect-ratio: 4 / 3;
		object-fit: contain;
		align-self: center;
	}

	> .image {
		width: 100%;
		aspect-ratio: 4 / 3;
		object-fit: contain;
		align-self: center;
	}
`;

export const ProductGroupDetails: React.FC = () => {
	const navigate = useNavigate();

	const [temporaryProductTypes, setTemporaryProductTypes] = useState<ProductType[]>();
	const { productGroupDetails, edit, removeProductGroup, changeOrder } = useProductGroupDetails();

	const productTypesSorted = useMemo(() => {
		return productGroupDetails?.types.sort((a, b) => a.order - b.order);
	}, [productGroupDetails?.types]);

	if (!productGroupDetails || !productTypesSorted) {
		return <Skeleton active />;
	}

	const editImage = async (file: File) => {
		try {
			const { buffer, extension } = await readFile(file);

			await edit({
				cover: buffer.toString('base64'),
				coverExtension: extension,
			});
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się wczytać pliku.');
		}
	};

	const onDragEnded = async (result: DropResult) => {
		const { source, destination } = result;
		if (!destination) {
			return;
		}

		const productTypes = [...productTypesSorted];

		// Remove the item from the source
		const item = productTypes.splice(source.index, 1)[0];
		if (!item) {
			return;
		}

		// Insert the item into the destination
		productTypes.splice(destination.index, 0, item);

		setTemporaryProductTypes(productTypes);
		await changeOrder(productTypes);
		setTemporaryProductTypes(undefined);
	};

	return (
		<Wrapper>
			<OfferBreadcrumbs />
			<Header>
				<div>
					<EditableText
						className="title"
						text={productGroupDetails.name}
						editButtonTooltip="Zmień nazwę"
						onChange={async (value) => {
							if (value === null) {
								return;
							}

							await edit({ name: value });
						}}
					/>
					{productTypesSorted.length > 0 ? (
						<Popconfirm
							title="Czy na pewno chcesz usunąć tę grupę? Spowoduje to usunięcie wszystkich rodzajów produktów w tej grupie."
							okText="Tak"
							cancelText="Nie"
							onConfirm={removeProductGroup}
						>
							<IconButton tooltip="Usuń grupę" color={red[5]!}>
								<DeleteOutlined />
							</IconButton>
						</Popconfirm>
					) : (
						<IconButton tooltip="Usuń grupę" color={red[5]!} onClick={removeProductGroup}>
							<DeleteOutlined />
						</IconButton>
					)}
				</div>

				<div>
					<p>Okładka: ({RECOMMENDED_COVER_DIMENSIONS})</p>
					<EditableImage
						className="image"
						src={`data:image/png;base64,${productGroupDetails.cover}`}
						alt="image"
						onImageChange={editImage}
					/>
				</div>
			</Header>
			<Divider>Lista rodzajów produktów</Divider>

			<Button type="primary" onClick={() => navigate('product_types/add')}>
				Dodaj rodzaj produktów
			</Button>

			<DragDropContext onDragEnd={onDragEnded}>
				<Droppable droppableId="product-types">
					{(provided) => (
						<CardList ref={provided.innerRef} {...provided.droppableProps}>
							{(temporaryProductTypes ?? productTypesSorted).map((productType, index) => (
								<Draggable draggableId={`product-type-${productType.id}`} index={index} key={productType.id}>
									{(_provided, _snapshot) => <ProductTypeCard provided={_provided} productType={productType} />}
								</Draggable>
							))}
							{provided.placeholder}
						</CardList>
					)}
				</Droppable>
			</DragDropContext>
		</Wrapper>
	);
};
