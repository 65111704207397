import React from 'react';
import { Skeleton } from 'antd';

interface Props {
	load: () => void;
}

export const ModuleLoading: React.FC<Props> = ({ load }) => {
	React.useEffect(() => {
		load();
	}, []);

	return <Skeleton active />;
};
