export const MAX_PRODUCT_SHORT_DESCRIPTION_LENGTH = 80;

export const RECOMMENDED_PRODUCT_PICTOGRAM_WIDTH = 256;
export const RECOMMENDED_PRODUCT_PICTOGRAM_HEIGHT = 256;
export const RECOMMENDED_PRODUCT_PICTOGRAM_DIMENSIONS = `${RECOMMENDED_PRODUCT_PICTOGRAM_WIDTH}x${RECOMMENDED_PRODUCT_PICTOGRAM_HEIGHT}`;
export const MAX_PRODUCT_PICTOGRAM_SIZE_KILOBYTES = 8;

export const RECOMMENDED_COVER_WIDTH = 1440;
export const RECOMMENDED_COVER_HEIGHT = 1080;
export const RECOMMENDED_COVER_DIMENSIONS = `${RECOMMENDED_COVER_WIDTH}x${RECOMMENDED_COVER_HEIGHT}`;
