import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { CurrentUserProvider } from 'shared';
import { AppRoutes } from './app_routes';

import locale from 'antd/lib/locale/pl_PL';

import 'antd/dist/antd.css';
import './app.scss';

interface Props {}

export const App: React.FC<Props> = ({}: Props) => {
	return (
		<ConfigProvider locale={locale}>
			<BrowserRouter>
				<CurrentUserProvider>
					<AppRoutes />
				</CurrentUserProvider>
			</BrowserRouter>
		</ConfigProvider>
	);
};
