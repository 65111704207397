import { API, ProductType, useCurrentUser } from 'shared';

export const useProductTypeCard = (productType: ProductType) => {
	const user = useCurrentUser();

	const loadFullCover = async () => {
		if (!user) {
			return null;
		}

		try {
			const response = await API(user).productType(productType.id).getCover();

			return Buffer.from(response.data).toString('base64');
		} catch (e) {
			console.trace(e);

			return null;
		}
	};

	return {
		loadFullCover,
	};
};
