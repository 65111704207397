export interface ProductType {
	id: number;
	name: string;
	description: string;
	coverThumbnail: string;
	cover: string;
	order: number;
	groupId: number;
	createdAt: string;
	createdBy: string;
}
