import { message } from 'antd';
import { API, Document, useCurrentUser } from 'shared';

export const useDocumentCard = (document: Document) => {
	const user = useCurrentUser();

	const openDocument = async () => {
		if (!user) {
			return;
		}

		try {
			const response = await API(user).product(document.id).document(document.id).getFile();

			const link = window.document.createElement('a');
			link.href = window.URL.createObjectURL(response.data);
			link.setAttribute('download', `${document.name}.pdf`);
			window.document.body.appendChild(link);

			link.click();

			window.document.body.removeChild(link);
		} catch (e) {
			message.error('Nie udało się otworzyć oferty');
		}
	};

	const deleteDocument = async () => {
		if (!user) {
			return;
		}

		try {
			await API(user).product(document.productId).document(document.id).delete();
		} catch (e) {
			console.trace(e);

			message.error('Nie udało się usunąć oferty');
		}
	};

	return {
		openDocument,
		deleteDocument,
	};
};
