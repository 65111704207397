import React, { useState } from 'react';
import { Button, Divider, Input } from 'antd';
import { grey, red } from '@ant-design/colors';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Apple from '../../assets/apple.svg';
import Google from '../../assets/google.svg';
import { useLogin } from './use_login';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #f0f2f5;

	> div {
		width: 400px;
		padding: 40px 30px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.15);
		border-radius: 5px;
		background: #fff;

		> h1 {
			margin-bottom: 15px;
		}

		> p.error {
			height: 21px;
			margin-bottom: 15px;

			color: ${() => red[5]};
		}

		> form {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			align-self: stretch;

			> span {
				margin-bottom: 10px;
				align-self: stretch;
			}

			> input {
				display: none;
			}

			> button {
				margin-top: 20px;
			}
		}

		> div.ant-divider {
			color: ${() => grey[1]};
			font-weight: 400;
			font-size: 0.95em;
		}

		> div.other-methods {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			row-gap: 10px;
			width: 210px;
		}

		> span {
			margin-bottom: 20px;

			svg {
				// fill: $icon;
			}
		}
	}

	> span {
		color: ${() => grey[3]};
	}
`;

const GoogleButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 100%;

	border: 1px solid #d3d3d3;
	background: #fff;
	border-radius: 5px;
	padding: 10px 20px;
	transition: 0.2s;
	font-weight: 500;

	> svg {
		margin-right: 10px;
	}

	&:hover {
		cursor: pointer;
		background: #f9f9f9;
	}
`;

const AppleButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 100%;

	border: none;
	background: #050708;
	border-radius: 5px;
	padding: 10px 20px;
	font-weight: 500;
	color: #fff;

	> svg {
		margin-right: 10px;
	}

	&:hover {
		cursor: pointer;
	}
`;

interface Props {
	onLogin: () => void;
}

export const Login: React.FC<Props> = ({ onLogin }: Props) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const { error, loading, loginWithPassword, loginWithGoogle, loginWithApple } = useLogin({ onLogin });

	const _onLogin = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();

		loginWithPassword(email, password);
	};

	return (
		<Wrapper>
			<div>
				<h1>Zaloguj się</h1>
				<p className="error">{error}</p>

				<form onSubmit={_onLogin}>
					<Input
						size="large"
						placeholder="Email"
						prefix={<UserOutlined />}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>

					<Input
						size="large"
						type="password"
						placeholder="Hasło"
						prefix={<LockOutlined />}
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>

					<input type="submit" />

					<Button size="large" type="primary" loading={loading} onClick={_onLogin}>
						Zaloguj
					</Button>
				</form>

				<Divider>lub</Divider>

				<div className="other-methods">
					<GoogleButton onClick={() => loginWithGoogle()}>
						<Google />
						Zaloguj się z Google
					</GoogleButton>

					<AppleButton onClick={() => loginWithApple()}>
						<Apple />
						Zaloguj się z Apple
					</AppleButton>
				</div>
			</div>

			<span>v2.4.3</span>
		</Wrapper>
	);
};
