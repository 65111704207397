import React from 'react';
import { Button } from 'antd';
import { blue } from '@ant-design/colors';
import { LogoutOutlined } from '@ant-design/icons';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { usePanel } from './use_panel';

interface TabProps {
	selected: boolean;
}

const Tab = styled.div<TabProps>`
	padding: 15px 30px;
	font-size: 14px;
	white-space: nowrap;

	border-bottom: 2px solid;
	border-bottom-color: ${({ selected }: TabProps) => (selected ? blue[5] : 'transparent')};

	&:hover {
		cursor: pointer;
	}
`;

const Header = styled.div`
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
	width: 100%;
	display: flex;
	align-items: center;
	padding-right: 16px;

	> div:first-child {
		flex: 1;
		display: flex;
		margin-right: 15px;

		overflow-x: scroll;

		/* Hide scrollbar for IE, Edge and Firefox */
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */

		&::-webkit-scrollbar {
			/* Hide scrollbar for Chrome, Safari and Opera */
			display: none;
		}
	}
`;

export const Panel: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { logout } = usePanel();

	return (
		<div>
			<Header>
				<div>
					<Tab
						selected={location.pathname.startsWith('/panel/product_groups')}
						onClick={() => navigate('product_groups', { replace: true })}
					>
						Oferta
					</Tab>
					<Tab
						selected={location.pathname.startsWith('/panel/users')}
						onClick={() => navigate('users', { replace: true })}
					>
						Użytkownicy
					</Tab>
				</div>

				<Button type="primary" className="header-logout" icon={<LogoutOutlined />} onClick={logout}>
					Wyloguj
				</Button>
			</Header>

			<Outlet />
		</div>
	);
};
