import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { styled } from 'styled-components';
import { ImageInput, RECOMMENDED_COVER_DIMENSIONS } from 'shared';
import { useAddProductGroup } from './use_add_product_group';

const Form = styled.div`
	padding: 32px 8px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 32px;

	> button {
		margin-top: 45px;
		align-self: center;
	}
`;

export const AddProductGroup: React.FC = () => {
	const { addProductGroup } = useAddProductGroup();
	const [loading, setLoading] = useState(false);

	const [name, setName] = useState('');
	const [cover, setCover] = useState<File | null>(null);

	const submit = async () => {
		if (!name || !cover) {
			return;
		}

		setLoading(true);

		await addProductGroup({
			name,
			cover,
		});

		setLoading(false);
	};

	return (
		<Form>
			<div>
				<h4>Nazwa wyświetlana w aplikacji:</h4>
				<Input type="text" size="large" value={name} onChange={(e) => setName(e.target.value)} />
			</div>

			<div>
				<h4>Okładka: ({RECOMMENDED_COVER_DIMENSIONS})</h4>
				<ImageInput value={cover} onChange={(file) => setCover(file)} />
			</div>

			<Button
				size="large"
				type="primary"
				icon={<PlusCircleOutlined />}
				disabled={!name || !cover}
				onClick={submit}
				loading={loading}
			>
				Dodaj grupę produktów
			</Button>
		</Form>
	);
};
