import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { styled } from 'styled-components';

const StyledCard = styled(Card)`
	width: 100%;

	:hover {
		cursor: pointer;
	}

	img {
		width: 100%;
		aspect-ratio: 4 / 3;
		object-fit: contain;
	}

	> .ant-card-body {
		display: flex;
		align-items: center;

		> .spacer {
			flex: 1;
		}

		> div:last-child {
			> span {
				font-size: 1.5em;
			}
		}
	}
`;

interface Props {
	thumbnail: string;
	title: string;
	description: string;
	loadCover: () => Promise<string | null>;
	onClick: () => void;
	actions?: React.ReactNode[];
	descriptionAction?: React.ReactNode;
}

export const CardWithThumbnail: React.FC<Props> = ({
	thumbnail,
	title,
	description,
	loadCover,
	onClick,
	actions = [],
	descriptionAction,
}: Props) => {
	const [cover, setCover] = useState<string | null>(null);

	useEffect(() => {
		_loadCover();
	}, []);

	const _loadCover = async () => {
		try {
			const cover = await loadCover();

			setCover(cover);
		} catch (e) {
			console.trace(e);
		}
	};

	return (
		<StyledCard
			cover={<img src={`data:image/png;base64,${cover ?? thumbnail}`} alt="preview" />}
			onClick={onClick}
			actions={actions}
		>
			<Meta title={title} description={description} />
			<div className="spacer"></div>
			{descriptionAction}
		</StyledCard>
	);
};
