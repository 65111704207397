import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useOfferBreadcrumbs } from './use_offer_breadcrumbs';
import styled from 'styled-components';

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: start;
`;

export const OfferBreadcrumbs: React.FC = () => {
	const navigate = useNavigate();
	const { productGroups, productGroup, productTypes, productType, products, product } = useOfferBreadcrumbs();

	if (!productGroup && !productType && !product) {
		return <div></div>;
	}

	const buildProductGroupBreadcrumb = () => {
		if (!productGroups || !productGroup) {
			return null;
		}

		const items = productGroups.map<ItemType>((productGroup) => ({
			key: productGroup.id,
			label: productGroup.name,
			onClick: () => navigate(`/panel/product_groups/${productGroup.id}`),
		}));

		return (
			<Breadcrumb.Item menu={{ items }}>
				<Link to={`/panel/product_groups/${productGroup.id}`}>{productGroup.name}</Link>
			</Breadcrumb.Item>
		);
	};

	const buildProductTypeBreadcrumb = () => {
		if (!productGroup || !productTypes || !productType) {
			return null;
		}

		const items = productTypes.map<ItemType>((productType) => ({
			key: productType.id,
			label: productType.name,
			onClick: () => navigate(`/panel/product_groups/${productGroup.id}/product_types/${productType.id}`),
		}));

		return (
			<Breadcrumb.Item menu={{ items }}>
				<Link to={`/panel/product_groups/${productGroup.id}/product_types/${productType.id}`}>{productType.name}</Link>
			</Breadcrumb.Item>
		);
	};

	const buildProductBreadcrumb = () => {
		if (!productGroup || !productType || !products || !product) {
			return null;
		}

		const items = products.map<ItemType>((product) => ({
			key: product.id,
			label: product.name,
			onClick: () =>
				navigate(`/panel/product_groups/${productGroup.id}/product_types/${productType.id}/products/${product.id}`),
		}));

		return (
			<Breadcrumb.Item menu={{ items }}>
				<Link to={`/panel/product_groups/${productGroup.id}/product_types/${productType.id}/products/${product.id}`}>
					{product.name}
				</Link>
			</Breadcrumb.Item>
		);
	};

	return (
		<Wrapper>
			<Breadcrumb>
				<Breadcrumb.Item>
					<Link to="/panel/product_groups">Oferta</Link>
				</Breadcrumb.Item>
				{buildProductGroupBreadcrumb()}
				{buildProductTypeBreadcrumb()}
				{buildProductBreadcrumb()}
			</Breadcrumb>
		</Wrapper>
	);
};
