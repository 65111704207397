import { useState } from 'react';
import {
	getAuth,
	GoogleAuthProvider,
	OAuthProvider,
	signInWithEmailAndPassword,
	signInWithPopup,
	User,
} from 'firebase/auth';
import * as sentry from '@sentry/react';
import { message } from 'antd';
import { API, Entitlement } from 'shared';

interface Params {
	onLogin: () => void;
}

export const useLogin = ({ onLogin }: Params) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>();

	const loginWithPassword = async (email: string, password: string) => {
		if (!email.trim()) return setError('Podaj email');
		if (!password.trim()) return setError('Podaj hasło');

		try {
			setLoading(true);

			const auth = getAuth();
			const credentials = await signInWithEmailAndPassword(auth, email, password);

			await _onLogin(credentials.user);

			setLoading(false);
		} catch (e) {
			setLoading(false);
			console.trace(e);

			setError('Niepoprawne dane logowania');
		}
	};

	const loginWithGoogle = async () => {
		try {
			const auth = getAuth();
			const provider = new GoogleAuthProvider();

			const credentials = await signInWithPopup(auth, provider);

			await _onLogin(credentials.user);
		} catch (e) {
			console.trace(e);

			message.error('Nie udało się zalogować');
		}
	};

	const loginWithApple = async () => {
		try {
			const auth = getAuth();
			const provider = new OAuthProvider('apple.com');

			const credentials = await signInWithPopup(auth, provider);

			await _onLogin(credentials.user);
		} catch (e) {
			console.trace(e);

			message.error('Nie udało się zalogować');
		}
	};

	const _onLogin = async (user: User) => {
		const response = await API(user).user(user.uid).entitlements.get();
		const hasAccessPanelEntitlement = response.data.indexOf(Entitlement.accessPanel) !== -1;
		if (!hasAccessPanelEntitlement) {
			message.error('Brak dostępu do panelu');

			const auth = getAuth();
			await auth.signOut();

			return;
		}

		sentry.setUser({
			id: user.uid,
			email: user.email ?? '',
			username: user.displayName ?? '',
		});

		onLogin();
	};

	return { loading, error, loginWithPassword, loginWithGoogle, loginWithApple };
};
