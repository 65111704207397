import { message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API, DocumentGroup, useCurrentUser } from 'shared';
import { DocumentDto } from './components/add_document_form';

export const useAddProduct = () => {
	const user = useCurrentUser();
	const navigate = useNavigate();
	const [documentGroups, setDocumentGroups] = useState<DocumentGroup[] | null>(null);

	const { productTypeId: rawId } = useParams();
	const productTypeId = useMemo(() => {
		if (!rawId) {
			return null;
		}

		const parsed = parseInt(rawId);
		if (isNaN(parsed)) {
			message.error('Nie znaleziono grupy produktów.');

			return null;
		}

		return parsed;
	}, [rawId]);

	useEffect(() => {
		fetchData();
	}, [user]);

	const fetchData = async () => {
		if (!user) {
			return;
		}

		try {
			const response = await API(user).documentGroups.get();

			setDocumentGroups(response.data);
		} catch (e) {
			console.trace(e);

			message.error('Nie udało się pobrać danych.');
		}
	};

	const addProduct = async ({
		name,
		shortDescription,
		description,
		pictogram,
		productCover,
		documents,
	}: {
		name: string;
		shortDescription: string;
		description: string;
		pictogram: File;
		productCover: File;
		documents: DocumentDto[];
	}) => {
		if (!user) {
			return;
		}

		if (!productTypeId) {
			return;
		}

		const convertedDocuments = [];
		for (const document of documents) {
			if (!document.file || !document.groupId || !document.name || !document.description) {
				continue;
			}

			const docFileExtension = document.file.type.split('/')[1];
			if (!docFileExtension) {
				message.error(`Incorrect file type ${document.file.type}`);

				continue;
			}

			const docFileArrayBuffer = await document.file.arrayBuffer();
			const docFileBase64 = Buffer.from(docFileArrayBuffer).toString('base64');

			convertedDocuments.push({
				name: document.name,
				description: document.description,
				file: docFileBase64,
				fileExtension: docFileExtension,
				documentGroupId: document.groupId,
			});
		}

		const pictogramArrayBuffer = await pictogram.arrayBuffer();
		const pictogramBase64 = Buffer.from(pictogramArrayBuffer).toString('base64');

		const productCoverExtension = productCover.type.split('/')[1];
		if (!productCoverExtension) {
			message.error(`Incorrect file type ${productCover.type}`);

			return;
		}

		const productCoverArrayBuffer = await productCover.arrayBuffer();
		const productCoverBase64 = Buffer.from(productCoverArrayBuffer).toString('base64');

		const response = await API(user).products.add({
			name: name,
			shortDescription: shortDescription,
			description: description,
			typeId: productTypeId,
			pictogram: pictogramBase64,
			cover: productCoverBase64,
			coverExtension: productCoverExtension,
			documents: convertedDocuments,
		});

		navigate(`/panel/product_groups/1/product_types/1/products/${response.data.id}`, { replace: true });
	};

	return { documentGroups, addProduct };
};
