import { message } from 'antd';
import { API, ProductGroup, useCurrentUser } from 'shared';

export const useProductGroupCard = (productGroup: ProductGroup) => {
	const user = useCurrentUser();

	const loadFullCover = async () => {
		if (!user) {
			return null;
		}

		try {
			const response = await API(user).productGroup(productGroup.id).getCover();

			return Buffer.from(response.data).toString('base64');
		} catch (e) {
			console.trace(e);

			return null;
		}
	};

	const switchIsHidden = async () => {
		if (!user) {
			return;
		}

		try {
			await API(user).productGroup(productGroup.id).edit({ isHidden: !productGroup.isHidden });
		} catch (e) {
			message.error('Nie udało się zmienić widoczności');

			console.trace(e);
		}
	};

	return {
		loadFullCover,
		switchIsHidden,
	};
};
