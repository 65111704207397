import React, { useState } from 'react';
import { Input } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { green, red } from '@ant-design/colors';
import { IconButton } from './icon_button';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	column-gap: 8px;
`;

interface Props {
	className?: string;
	text: string;
	editButtonTooltip?: string;
	onChange: (text: string | null) => Promise<void>;
	textArea?: boolean;
}

export const EditableText: React.FC<Props> = ({ className, text, editButtonTooltip, onChange, textArea = false }) => {
	const [editedValue, setEditedValue] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState(false);

	const save = async () => {
		if (loading) {
			return;
		}

		if (editedValue === undefined) {
			return;
		}

		if (editedValue === text) {
			setEditedValue(undefined);

			return;
		}

		setLoading(true);

		if (editedValue.trim() === '') {
			await onChange(null);
		} else {
			await onChange(editedValue);
		}

		setEditedValue(undefined);
		setLoading(false);
	};

	const buildValue = () => {
		if (editedValue === undefined) {
			return <span>{text}</span>;
		}

		if (textArea) {
			return <Input.TextArea disabled={loading} value={editedValue} onChange={(e) => setEditedValue(e.target.value)} />;
		}

		return <Input disabled={loading} value={editedValue} onChange={(e) => setEditedValue(e.target.value)} />;
	};

	const buildButtons = () => {
		if (editedValue === undefined) {
			return (
				<IconButton tooltip={editButtonTooltip} onClick={() => setEditedValue(text)} disabled={loading}>
					<EditOutlined />
				</IconButton>
			);
		}

		return (
			<>
				<IconButton tooltip="Zapisz" onClick={save} color={green[3]!} disabled={loading}>
					<CheckOutlined />
				</IconButton>
				<IconButton tooltip="Anuluj" onClick={() => setEditedValue(undefined)} color={red[5]!} disabled={loading}>
					<CloseOutlined />
				</IconButton>
			</>
		);
	};

	return (
		<Wrapper className={className}>
			{buildValue()}
			{buildButtons()}
		</Wrapper>
	);
};
