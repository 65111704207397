import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { Button, Skeleton } from 'antd';
import { ProductGroupCard } from './components/product_group_card';
import { ProductGroup } from 'shared';
import styled from 'styled-components';

import { useProductGroups } from './use_product_groups';

const Wrapper = styled.div`
	padding: 8px;
	padding-top: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 32px;
`;

const CardList = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	> div {
		width: 100%;
		max-width: 350px;
		margin: 8px 0;
	}
`;

export const ProductGroups: React.FC = () => {
	const navigate = useNavigate();

	const [temporaryProductGroups, setTemporaryProductGroups] = useState<ProductGroup[]>();
	const { productGroups, changeOrder, refetch } = useProductGroups();

	const productGroupsSorted = useMemo(() => productGroups?.sort((a, b) => a.order - b.order), [productGroups]);
	if (!productGroupsSorted) {
		return <Skeleton active />;
	}

	const onDragEnded = async (result: DropResult) => {
		const { source, destination } = result;
		if (!destination) {
			return;
		}

		const productGroups = [...productGroupsSorted];

		// Remove the item from the source
		const item = productGroups.splice(source.index, 1)[0];
		if (!item) {
			return;
		}

		// Insert the item into the destination
		productGroups.splice(destination.index, 0, item);

		setTemporaryProductGroups(productGroups);
		await changeOrder(productGroups);
		setTemporaryProductGroups(undefined);
	};

	return (
		<Wrapper>
			<h1>Grupy produktów</h1>
			<Button type="primary" onClick={() => navigate('add')}>
				Dodaj grupę produktów
			</Button>

			<DragDropContext onDragEnd={onDragEnded}>
				<Droppable droppableId="product-groups">
					{(provided) => (
						<CardList ref={provided.innerRef} {...provided.droppableProps}>
							{(temporaryProductGroups ?? productGroupsSorted).map((productGroup, index) => (
								<Draggable draggableId={`product-group-${productGroup.id}`} index={index} key={productGroup.id}>
									{(_provided, _snapshot) => (
										<ProductGroupCard provided={_provided} productGroup={productGroup} refetch={refetch} />
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</CardList>
					)}
				</Droppable>
			</DragDropContext>
		</Wrapper>
	);
};
