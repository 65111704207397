import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { styled } from 'styled-components';

const Wrapper = styled.div`
	cursor: pointer;

	&.disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
`;

interface Props {
	className?: string;
	children: React.ReactNode;
	onClick: () => Promise<void>;
	tooltip?: string | undefined;
}

export const AsyncButton: React.FC<Props> = ({ className, children, onClick, tooltip }) => {
	const [loading, setLoading] = useState(false);

	const _onClick = async (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (loading) {
			return;
		}

		setLoading(true);
		await onClick();
		setLoading(false);
	};

	return (
		<Wrapper className={`${className ?? ''} ${loading ? 'disabled' : ''}`} onClick={_onClick}>
			<Tooltip popupVisible={!!tooltip} title={tooltip}>
				{children}
			</Tooltip>
		</Wrapper>
	);
};
