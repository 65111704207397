import React, { useEffect, useState } from 'react';
import { Input, Select, Skeleton } from 'antd';
import { styled } from 'styled-components';
import { DocumentGroup, FileInput } from 'shared';

export interface DocumentDto {
	file?: File | undefined;
	name?: string | undefined;
	description?: string | undefined;
	groupId?: number | undefined;
}

const Form = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 32px;

	> button {
		margin-top: 45px;
		align-self: center;
	}
`;

interface Props {
	onChange: (document: DocumentDto) => void;
	documentGroups: DocumentGroup[];
}

export const AddDocumentForm: React.FC<Props> = ({ documentGroups, onChange }) => {
	const [groupId, setGroupId] = useState<number>();
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [file, setFile] = useState<File>();

	useEffect(() => {
		const newDocument: DocumentDto = {
			groupId: groupId,
			name: name,
			description: description,
			file: file,
		};

		onChange(newDocument);
	}, [groupId, name, description, file]);

	if (!documentGroups) {
		return <Skeleton active />;
	}

	return (
		<Form>
			<div>
				<h4>Grupa dokumentu:</h4>
				<div>
					<Select
						style={{ width: '100%' }}
						placeholder="Wybierz grupę dokumentu"
						value={groupId}
						onChange={(value) => setGroupId(value)}
						options={documentGroups.map((productType) => ({ label: productType.name, value: productType.id }))}
						filterOption={(input, option) => (option?.label.toLowerCase().indexOf(input.toLowerCase()) ?? -1) >= 0}
					/>
				</div>
			</div>
			<div>
				<h4>Nazwa wyświetlana w aplikacji:</h4>
				<Input type="text" size="large" value={name} onChange={(e) => setName(e.target.value)} />
			</div>
			<div>
				<h4>Opis:</h4>
				<Input.TextArea size="large" value={description} onChange={(e) => setDescription(e.target.value)} />
			</div>
			<div>
				<h4>Plik dokumentu:</h4>
				<FileInput acceptedFileTypes="application/pdf" value={file ?? null} onChange={setFile} />
			</div>
		</Form>
	);
};
