import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DraggableProvided } from 'react-beautiful-dnd';
import { CardWithThumbnail, ProductGroup } from 'shared';

import { useProductGroupCard } from './use_product_group_card';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { AsyncButton } from 'shared/components/async_button';

interface Props {
	productGroup: ProductGroup;
	provided: DraggableProvided;
	refetch: () => Promise<void>;
}

export const ProductGroupCard: React.FC<Props> = ({ productGroup, provided, refetch }) => {
	const navigate = useNavigate();

	const { loadFullCover, switchIsHidden } = useProductGroupCard(productGroup);

	const onClickAction = async () => {
		await switchIsHidden();
		await refetch();
	};

	return (
		<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
			<CardWithThumbnail
				thumbnail={productGroup.coverThumbnail}
				title={productGroup.name}
				description=""
				loadCover={loadFullCover}
				onClick={() => navigate(`/panel/product_groups/${productGroup.id}`)}
				descriptionAction={
					<AsyncButton onClick={onClickAction} tooltip="Edytuj widoczność grupy">
						{productGroup.isHidden ? <EyeInvisibleOutlined /> : <EyeOutlined />}
					</AsyncButton>
				}
			/>
		</div>
	);
};
