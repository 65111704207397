import React, { useMemo } from 'react';
import { Table, TableProps, message } from 'antd';
import { API, AsyncCheckbox, Entitlement, SPECIAL_DOCUMENT_GROUP_ID, User, formatDate, useCurrentUser } from 'shared';

interface Props {
	users: User[];
	reload: () => Promise<void>;
}

interface Data extends User {
	accessToPanel: boolean;
	accessToSelectedDocuments: boolean;
}

export const UserTable: React.FC<Props> = ({ users, reload }) => {
	const currentUser = useCurrentUser();

	const changeAccessToPanel = async (user: User, access: boolean) => {
		if (!currentUser) {
			message.error('Nie jesteś zalogowany');
			return;
		}

		try {
			const entitlements = new Set(user.entitlements);
			if (access) {
				entitlements.add(Entitlement.addProductGroup);
				entitlements.add(Entitlement.editProductGroup);
				entitlements.add(Entitlement.deleteProductGroup);
				entitlements.add(Entitlement.addProductType);
				entitlements.add(Entitlement.editProductType);
				entitlements.add(Entitlement.deleteProductType);
				entitlements.add(Entitlement.addProduct);
				entitlements.add(Entitlement.editProduct);
				entitlements.add(Entitlement.deleteProduct);
				entitlements.add(Entitlement.addDocument);
				entitlements.add(Entitlement.editDocument);
				entitlements.add(Entitlement.deleteDocument);
				entitlements.add(Entitlement.addDocumentGroup);
				entitlements.add(Entitlement.editDocumentGroup);
				entitlements.add(Entitlement.deleteDocumentGroup);
				entitlements.add(Entitlement.listProfiles);
				entitlements.add(Entitlement.deleteProfile);
				entitlements.add(Entitlement.listEntitlements);
				entitlements.add(Entitlement.patchEntitlements);
				entitlements.add(Entitlement.accessPanel);
				entitlements.add(Entitlement.accessAllDocuments);
			} else {
				entitlements.delete(Entitlement.addProductGroup);
				entitlements.delete(Entitlement.editProductGroup);
				entitlements.delete(Entitlement.deleteProductGroup);
				entitlements.delete(Entitlement.addProductType);
				entitlements.delete(Entitlement.editProductType);
				entitlements.delete(Entitlement.deleteProductType);
				entitlements.delete(Entitlement.addProduct);
				entitlements.delete(Entitlement.editProduct);
				entitlements.delete(Entitlement.deleteProduct);
				entitlements.delete(Entitlement.addDocument);
				entitlements.delete(Entitlement.editDocument);
				entitlements.delete(Entitlement.deleteDocument);
				entitlements.delete(Entitlement.addDocumentGroup);
				entitlements.delete(Entitlement.editDocumentGroup);
				entitlements.delete(Entitlement.deleteDocumentGroup);
				entitlements.delete(Entitlement.listProfiles);
				entitlements.delete(Entitlement.deleteProfile);
				entitlements.delete(Entitlement.listEntitlements);
				entitlements.delete(Entitlement.patchEntitlements);
				entitlements.delete(Entitlement.accessPanel);
				entitlements.delete(Entitlement.accessAllDocuments);
			}

			await API(currentUser).user(user.userId).entitlements.update(Array.from(entitlements));

			await reload();
		} catch (error) {
			message.error('Nie udało się zmienić dostępu do panelu');

			console.trace(error);
		}
	};

	const changeAccessToSelectedDocuments = async (user: User, access: boolean) => {
		if (!currentUser) {
			message.error('Nie jesteś zalogowany');
			return;
		}

		try {
			const existingDocumentGroups = new Set(user.documentGroupIds);
			if (access) {
				existingDocumentGroups.add(SPECIAL_DOCUMENT_GROUP_ID);
			} else {
				existingDocumentGroups.delete(SPECIAL_DOCUMENT_GROUP_ID);
			}

			await API(currentUser).user(user.userId).documentGroups.change(Array.from(existingDocumentGroups));

			await reload();
		} catch (error) {
			message.error('Nie udało się zmienić dostępu do panelu');

			console.trace(error);
		}
	};

	const data = useMemo<Data[]>(() => {
		return users.map((user) => ({
			...user,
			accessToPanel: user.entitlements.indexOf(Entitlement.accessPanel) !== -1,
			accessToSelectedDocuments: user.documentGroupIds.indexOf(SPECIAL_DOCUMENT_GROUP_ID) !== -1,
		}));
	}, [users]);

	const columns: TableProps<Data>['columns'] = [
		{
			title: 'Imię',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Nazwisko',
			dataIndex: 'surname',
			key: 'surname',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Telefon',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
		},
		{
			title: 'NIP',
			dataIndex: 'companyNumber',
			key: 'companyNumber',
			render: (companyNumber: string | null) => (companyNumber ? companyNumber : 'Brak'),
		},
		{
			title: 'Email zweryfikowany',
			dataIndex: 'emailVerified',
			key: 'emailVerified',
			render: (emailVerified: boolean) => (emailVerified ? 'Tak' : 'Nie'),
		},
		{
			title: 'Data utworzenia',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: formatDate,
		},
		{
			title: 'Dostęp do panelu',
			dataIndex: 'accessToPanel',
			key: 'accessToPanel',
			render: (accessToPanel: boolean, data: Data) => {
				return <AsyncCheckbox value={accessToPanel} onChange={(value) => changeAccessToPanel(data, value)} />;
			},
		},
		{
			title: 'Dostęp do wybranych dokumentów',
			dataIndex: 'accessToSelectedDocuments',
			key: 'accessToSelectedDocuments',
			render: (accessToSelectedDocuments: boolean, data: Data) => {
				return (
					<AsyncCheckbox
						value={accessToSelectedDocuments}
						onChange={(value) => changeAccessToSelectedDocuments(data, value)}
					/>
				);
			},
		},
	];

	return <Table columns={columns} dataSource={data} pagination={false} bordered={true} scroll={{ x: 'max-content' }} />;
};
