import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { Button, Divider, Popconfirm, Skeleton, message } from 'antd';
import { grey, red } from '@ant-design/colors';
import { DeleteOutlined } from '@ant-design/icons';
import { DocumentCard } from './document_card/document_card';
import {
	Document,
	EditableImage,
	EditableText,
	IconButton,
	MAX_PRODUCT_PICTOGRAM_SIZE_KILOBYTES,
	MAX_PRODUCT_SHORT_DESCRIPTION_LENGTH,
	OfferBreadcrumbs,
	RECOMMENDED_COVER_DIMENSIONS,
	RECOMMENDED_PRODUCT_PICTOGRAM_DIMENSIONS,
	readFile,
} from 'shared';
import { styled } from 'styled-components';

import { useProductDetails } from './use_product_details';

const Wrapper = styled.div`
	padding: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 32px;
`;

const CardList = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-self: center;
	justify-content: center;
	min-width: 400px;
	max-width: 800px;

	> div {
		margin: 8px 0;
	}
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	max-width: 800px;
	gap: 16px;

	> div {
		&:first-child {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 8px;
			column-gap: 16px;

			> .title > span {
				font-size: 24px;
				font-weight: bold;
			}
		}

		&.short-description-and-pictogram {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			> .pictogram {
				display: flex;
				flex-direction: column;
				align-items: end;
				margin-right: 48px;

				> div {
					max-width: 64px;
					aspect-ratio: 1;
					object-fit: contain;

					> span {
						right: -48px;
						top: 0px;
					}
				}
			}
		}

		&.image {
			width: 100%;
			align-self: center;
			max-width: 400px;

			> div {
				aspect-ratio: 4 / 3;
				object-fit: contain;
			}
		}

		> p,
		&.short-description-and-pictogram > div > p {
			margin: 0;
			font-size: 0.9em;
			color: ${grey[3]};
		}
	}
`;

export const ProductDetails: React.FC = () => {
	const navigate = useNavigate();
	const [temporaryDocuments, setTemporaryDocuments] = useState<Document[]>();
	const { product, refetch, removeProduct, edit, changeOrder } = useProductDetails();

	const documentsSorted = useMemo(() => product?.documents.sort((a, b) => a.order - b.order), [product]);

	if (!product || !documentsSorted) {
		return <Skeleton active />;
	}

	const editPictogram = async (file: File) => {
		if (file.size > 1024 * MAX_PRODUCT_PICTOGRAM_SIZE_KILOBYTES) {
			message.error(`Plik jest zbyt duży. Maksymalny rozmiar to ${MAX_PRODUCT_PICTOGRAM_SIZE_KILOBYTES}KB.`);
			return;
		}

		try {
			const { buffer } = await readFile(file);

			await edit({
				pictogram: buffer.toString('base64'),
			});
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się wczytać pliku.');
		}
	};

	const editImage = async (file: File) => {
		try {
			const { buffer, extension } = await readFile(file);

			await edit({
				cover: buffer.toString('base64'),
				coverExtension: extension,
			});
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się wczytać pliku.');
		}
	};

	const onDragEnded = async (result: DropResult) => {
		const { source, destination } = result;
		if (!destination) {
			return;
		}

		const documents = [...documentsSorted];

		// Remove the item from the source
		const item = documents.splice(source.index, 1)[0];
		if (!item) {
			return;
		}

		// Insert the item into the destination
		documents.splice(destination.index, 0, item);

		setTemporaryDocuments(documents);
		await changeOrder(documents);
		setTemporaryDocuments(undefined);
	};

	return (
		<Wrapper>
			<OfferBreadcrumbs />
			<Header>
				<div>
					<EditableText
						className="title"
						text={product.name}
						editButtonTooltip="Zmień nazwę"
						onChange={async (value) => {
							if (value === null) {
								return;
							}

							await edit({ name: value });
						}}
					/>
					{product.documents.length > 0 ? (
						<Popconfirm
							title="Czy na pewno chcesz usunąć ten produkt? Spowoduje to usunięcie wszystkich dokumentów tego produktu."
							okText="Tak"
							cancelText="Nie"
							onConfirm={removeProduct}
						>
							<IconButton tooltip="Usuń produkt" color={red[5]!}>
								<DeleteOutlined />
							</IconButton>
						</Popconfirm>
					) : (
						<IconButton tooltip="Usuń grupę" color={red[5]!} onClick={removeProduct}>
							<DeleteOutlined />
						</IconButton>
					)}
				</div>
				<div className="short-description-and-pictogram">
					<div className="short-description">
						<p>Krótki opis: (max {MAX_PRODUCT_SHORT_DESCRIPTION_LENGTH} znaków)</p>
						<EditableText
							text={product.shortDescription}
							editButtonTooltip="Zmień krótki opis"
							textArea
							onChange={async (value) => {
								if (value === null) {
									return;
								}

								if (value.length > MAX_PRODUCT_SHORT_DESCRIPTION_LENGTH) {
									message.error(`Krótki opis nie może być dłuższy niż ${MAX_PRODUCT_SHORT_DESCRIPTION_LENGTH} znaków.`);
									return;
								}

								await edit({ shortDescription: value });
							}}
						/>
					</div>
					<div className="pictogram">
						<p>
							Piktogram: ({RECOMMENDED_PRODUCT_PICTOGRAM_DIMENSIONS} - max {MAX_PRODUCT_PICTOGRAM_SIZE_KILOBYTES}KB)
						</p>
						<EditableImage
							src={`data:image/png;base64,${product.pictogram}`}
							alt="piktogram"
							onImageChange={editPictogram}
						/>
					</div>
				</div>
				<div className="description">
					<p>Opis:</p>
					<EditableText
						text={product.description}
						editButtonTooltip="Zmień opis"
						textArea
						onChange={async (value) => {
							if (value === null) {
								return;
							}

							await edit({ description: value });
						}}
					/>
				</div>
				<div className="image">
					<p>Zdjęcie: ({RECOMMENDED_COVER_DIMENSIONS})</p>
					<EditableImage src={`data:image/png;base64,${product.cover}`} alt="image" onImageChange={editImage} />
				</div>
			</Header>
			<Divider>Lista dokumentów</Divider>
			<Button type="primary" onClick={() => navigate('add-document')}>
				Dodaj dokument
			</Button>

			<DragDropContext onDragEnd={onDragEnded}>
				<Droppable droppableId="documents">
					{(provided) => (
						<CardList ref={provided.innerRef} {...provided.droppableProps}>
							{(temporaryDocuments ?? documentsSorted).map((document, index) => (
								<Draggable draggableId={`document-${document.id}`} index={index} key={document.id}>
									{(_provided, _snapshot) => (
										<DocumentCard provided={_provided} refetch={refetch} document={document} />
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</CardList>
					)}
				</Droppable>
			</DragDropContext>
		</Wrapper>
	);
};
