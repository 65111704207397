import { message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API, EditProductDto, Document, ProductDetails, useCurrentUser } from 'shared';

export const useProductDetails = () => {
	const { productId: rawId } = useParams();
	const navigate = useNavigate();
	const id = useMemo(() => {
		if (!rawId) {
			return null;
		}

		const parsed = parseInt(rawId);
		if (isNaN(parsed)) {
			message.error('Nie znaleziono produktu.');

			return null;
		}

		return parsed;
	}, [rawId]);

	const user = useCurrentUser();

	const [product, setProduct] = useState<ProductDetails | null>(null);

	useEffect(() => {
		setProduct(null);

		fetchData();
	}, [id, user]);

	const fetchData = async () => {
		if (!id || !user) {
			return;
		}

		try {
			const response = await API(user).product(id).get();

			setProduct(response.data);
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się załadować oferty.');
		}
	};

	const edit = async (dto: EditProductDto) => {
		if (!user || !id) {
			return;
		}

		try {
			await API(user).product(id).edit(dto);

			await fetchData();
		} catch (error) {
			console.trace(error);

			message.error('Edycja nie powiodła się.');
		}
	};

	const removeProduct = async () => {
		if (!user || !id) {
			return;
		}

		try {
			await API(user).product(id).delete();
			navigate(`/panel/product_groups/${product?.groupId}/product_types/${product?.typeId}`, { replace: true });
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się usunąć produktu.');
		}
	};

	const changeOrder = async (documents: Document[]) => {
		if (!user || !product) {
			message.error('Nie udało się zmienić kolejności.');

			return;
		}

		try {
			await API(user)
				.product(product.id)
				.documents.editOrder(
					product.groupId,
					product.typeId,
					documents.map((document) => document.id)
				);

			await fetchData();
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się zmienić kolejności.');
		}
	};

	return {
		product,
		refetch: fetchData,
		removeProduct,
		edit,
		changeOrder,
	};
};
