import { message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API, useCurrentUser, ProductGroupDetails, EditProductGroupDto, ProductType } from 'shared';

export const useProductGroupDetails = () => {
	const navigate = useNavigate();
	const { productGroupId: rawId } = useParams();

	const id = useMemo(() => {
		if (!rawId) {
			return null;
		}

		const parsed = parseInt(rawId);
		if (isNaN(parsed)) {
			message.error('Nie znaleziono grupy produktów.');

			return null;
		}

		return parsed;
	}, [rawId]);

	const user = useCurrentUser();
	const [productGroupDetails, setProductGroupDetails] = useState<ProductGroupDetails | null>(null);

	useEffect(() => {
		setProductGroupDetails(null);

		fetchData();
	}, [user, id]);

	const fetchData = async () => {
		if (!user || !id) {
			return;
		}

		try {
			const response = await API(user).productGroup(id).get();

			setProductGroupDetails(response.data);
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się pobrać danych.');
		}
	};

	const edit = async (dto: EditProductGroupDto) => {
		if (!user || !id) {
			return;
		}

		try {
			await API(user).productGroup(id).edit(dto);

			await fetchData();
		} catch (error) {
			console.trace(error);

			message.error('Edycja nie powiodła się.');
		}
	};

	const removeProductGroup = async () => {
		if (!user || !id) {
			return;
		}

		try {
			await API(user).productGroup(id).delete();

			navigate('/panel/product_groups', { replace: true });
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się usunąć grupy produktów.');
		}
	};

	const changeOrder = async (productTypes: ProductType[]) => {
		if (!user || !productGroupDetails) {
			message.error('Nie udało się zmienić kolejności.');

			return;
		}

		try {
			await API(user).productTypes.editOrder(
				productGroupDetails.id,
				productTypes.map((type) => type.id)
			);

			await fetchData();
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się zmienić kolejności.');
		}
	};

	return {
		productGroupDetails,
		edit,
		removeProductGroup,
		changeOrder,
	};
};
