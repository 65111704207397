export interface ProductGroup {
	id: number;
	name: string;
	coverThumbnail: string;
	cover: string;
	order: number;
	isHidden: boolean;
	createdAt: string;
	createdBy: string;
}
