import React, { useState } from 'react';
import { useAddDocument } from './use_add_document';
import { styled } from 'styled-components';
import { AddDocumentForm, DocumentDto } from '../add_product/components/add_document_form';
import { Button, Skeleton } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

const Form = styled.div`
	padding: 32px 8px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 32px;

	> button {
		margin-top: 45px;
		align-self: center;
	}
`;

export const AddDocument: React.FC = () => {
	const { documentGroups, addDocument } = useAddDocument();
	const [document, setDocument] = useState<DocumentDto>({});
	const [loading, setLoading] = useState<boolean>(false);

	const submit = async () => {
		setLoading(true);

		await addDocument({
			document,
		});

		setLoading(false);
	};

	const onChangeDocument = (newDocument: DocumentDto) => {
		setDocument(newDocument);
	};

	if (!documentGroups) {
		return <Skeleton active />;
	}

	return (
		<Form>
			<AddDocumentForm onChange={(newDocument) => onChangeDocument(newDocument)} documentGroups={documentGroups} />

			<Button
				size="large"
				type="primary"
				icon={<PlusCircleOutlined />}
				disabled={!document.file || !document.groupId || !document.name || !document.description}
				onClick={submit}
				loading={loading}
			>
				Dodaj dokument
			</Button>
		</Form>
	);
};
