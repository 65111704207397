import React, { useState } from 'react';
import { Checkbox } from 'antd';

interface Props {
	value: boolean;
	onChange: (value: boolean) => Promise<void>;
}

export const AsyncCheckbox: React.FC<Props> = ({ value, onChange }) => {
	const [loading, setLoading] = useState(false);

	const _onChange = async (value: boolean) => {
		setLoading(true);
		await onChange(value);
		setLoading(false);
	};

	return (
		<Checkbox checked={loading ? !value : value} disabled={loading} onChange={(e) => _onChange(e.target.checked)} />
	);
};
