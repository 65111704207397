export const makeNullIfEmpty = (value: string | null | undefined) => {
	if (!value) {
		return null;
	}

	if (value.trim() === '') {
		return null;
	}

	return value;
};
