import React from 'react';
import { useUsers } from './use_users';
import { Skeleton } from 'antd';
import { UserTable } from './user_table';
import styled from 'styled-components';

const Wrapper = styled.div`
	padding: 32px;
`;

export const Users: React.FC = () => {
	const { users, reload } = useUsers();

	if (!users) {
		return <Skeleton active />;
	}

	return (
		<Wrapper>
			<UserTable users={users} reload={reload} />
		</Wrapper>
	);
};
