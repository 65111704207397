import React, { useState } from 'react';
import { Button, Collapse, Divider, Input, Popconfirm, Skeleton, message } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { styled } from 'styled-components';
import {
	ImageInput,
	MAX_PRODUCT_PICTOGRAM_SIZE_KILOBYTES,
	MAX_PRODUCT_SHORT_DESCRIPTION_LENGTH,
	RECOMMENDED_COVER_DIMENSIONS,
	RECOMMENDED_PRODUCT_PICTOGRAM_DIMENSIONS,
	makeNullIfEmpty,
} from 'shared';
import { useAddProduct } from './use_add_product';
import { AddDocumentForm, DocumentDto } from './components/add_document_form';

const Form = styled.div`
	padding: 32px 8px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 32px;

	> button {
		margin-top: 45px;
		align-self: center;
	}
`;

const CenteredHeader = styled.div`
	text-align: center;
`;

export const AddProduct: React.FC = () => {
	const { documentGroups, addProduct } = useAddProduct();
	const [loading, setLoading] = useState<boolean>(false);

	const [name, setName] = useState('');
	const [shortDescription, setShortDescription] = useState('');
	const [description, setDescription] = useState('');
	const [pictogram, setPictogram] = useState<File>();
	const [cover, setCover] = useState<File>();
	const [documents, setDocuments] = useState<DocumentDto[]>([{}]);

	const submit = async () => {
		if (!pictogram) {
			return;
		}

		if (!cover) {
			return;
		}

		if (shortDescription.length > MAX_PRODUCT_SHORT_DESCRIPTION_LENGTH) {
			message.error(`Krótki opis nie może być dłuższy niż ${MAX_PRODUCT_SHORT_DESCRIPTION_LENGTH} znaków`);
			return;
		}

		if (pictogram.size > 1024 * MAX_PRODUCT_PICTOGRAM_SIZE_KILOBYTES) {
			message.error(`Piktogram jest zbyt duży. Maksymalny rozmiar to ${MAX_PRODUCT_PICTOGRAM_SIZE_KILOBYTES}KB.`);
			return;
		}

		for (const document of documents) {
			if (!document.file || !document.groupId || !document.name || !document.description) {
				message.error('Wypełnij wszystkie pola');
				return;
			}
		}

		setLoading(true);

		await addProduct({
			name: name,
			shortDescription: shortDescription,
			description: description,
			pictogram: pictogram,
			productCover: cover,
			documents: documents,
		});

		setLoading(false);
	};

	const onChangeDocument = (index: number, newDocument: DocumentDto) => {
		const newDocuments = [...documents];
		newDocuments[index] = newDocument;
		setDocuments(newDocuments);
	};

	const addDocument = () => {
		setDocuments([...documents, {}]);
	};

	const removeDocument = (index: number) => {
		const newDocuments = [...documents];
		newDocuments.splice(index, 1);
		setDocuments(newDocuments);
	};

	const getIcon = (index: number, document: DocumentDto) =>
		!document.file && !document.groupId && !document.name && !document.description ? (
			<DeleteOutlined
				onClick={(_) => {
					removeDocument(index);
				}}
			></DeleteOutlined>
		) : (
			<Popconfirm
				title="Czy na pewno chcesz usunąć ten dokument?"
				okText="Tak"
				cancelText="Nie"
				onCancel={(event) => {
					event?.preventDefault();
					event?.stopPropagation();
				}}
				onConfirm={(event) => {
					event?.preventDefault();
					event?.stopPropagation();
					if (!document.file && !document.groupId && !document.name && !document.description) {
						removeDocument(index);
						return;
					}

					removeDocument(index);
				}}
			>
				<DeleteOutlined
					onClick={(event) => {
						event?.preventDefault();
						event?.stopPropagation();
					}}
				/>
			</Popconfirm>
		);

	if (!documentGroups) {
		return <Skeleton active />;
	}

	return (
		<Form>
			<div>
				<h4>Nazwa wyświetlana w aplikacji:</h4>
				<Input type="text" size="large" value={name} onChange={(e) => setName(e.target.value)} />
			</div>
			<div>
				<h4>Krótki opis: (max {MAX_PRODUCT_SHORT_DESCRIPTION_LENGTH} znaków)</h4>
				<Input value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} />
			</div>
			<div>
				<h4>Opis:</h4>
				<Input.TextArea size="large" value={description} onChange={(e) => setDescription(e.target.value)} />
			</div>
			<div>
				<h4>
					Piktogram: ({RECOMMENDED_PRODUCT_PICTOGRAM_DIMENSIONS} - max {MAX_PRODUCT_PICTOGRAM_SIZE_KILOBYTES}KB)
				</h4>
				<ImageInput
					value={pictogram ?? null}
					onChange={(file) => {
						if (file.size > 1024 * 8) {
							message.error(`Plik jest zbyt duży. Maksymalny rozmiar to ${MAX_PRODUCT_PICTOGRAM_SIZE_KILOBYTES}KB.`);
							return;
						}

						setPictogram(file);
					}}
				/>
			</div>
			<div>
				<h4>Okładka: ({RECOMMENDED_COVER_DIMENSIONS})</h4>
				<ImageInput value={cover ?? null} onChange={(file) => setCover(file)} />
			</div>
			<Divider>Dokumenty</Divider>
			{documents.length == 0 ? (
				<CenteredHeader>Brak dokumentów</CenteredHeader>
			) : (
				<Collapse defaultActiveKey={['0']}>
					{documents.map((document, index) => (
						<Collapse.Panel
							extra={getIcon(index, document)}
							header={makeNullIfEmpty(documents?.[index]?.name) ?? `Dokument ${index}`}
							key={index}
						>
							<AddDocumentForm
								onChange={(newDocument) => onChangeDocument(index, newDocument)}
								documentGroups={documentGroups}
							></AddDocumentForm>
						</Collapse.Panel>
					))}
				</Collapse>
			)}

			<Button size="large" type="primary" icon={<PlusCircleOutlined />} onClick={addDocument}>
				{documents.length == 0 ? 'Dodaj dokument' : 'Dodaj kolejny dokument'}
			</Button>
			<Button
				size="large"
				type="primary"
				icon={<PlusCircleOutlined />}
				disabled={name === '' || !cover}
				onClick={submit}
				loading={loading}
			>
				Dodaj produkt
			</Button>
		</Form>
	);
};
