import { message } from 'antd';
import { User } from 'firebase/auth';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API, Product, ProductGroup, ProductType, useCurrentUser } from 'shared';

export const useOfferBreadcrumbs = () => {
	const user = useCurrentUser();
	const { productGroupId, productTypeId, productId } = useParams();

	const [productGroups, setProductGroups] = useState<ProductGroup[]>();
	const [productTypes, setProductTypes] = useState<ProductType[]>();
	const [products, setProducts] = useState<Product[]>();

	useEffect(() => {
		if (!user) {
			setProductGroups(undefined);
			setProductTypes(undefined);
			setProducts(undefined);

			return;
		}

		fetchProductGroups(user);
		fetchProductTypes(user);
		fetchProducts(user);
	}, [user]);

	const fetchProductGroups = async (user: User) => {
		try {
			const result = await API(user).productGroups.get();

			setProductGroups(result.data);
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się załadować wszystkich danych');
		}
	};

	const fetchProductTypes = async (user: User) => {
		try {
			const result = await API(user).productTypes.get({});

			setProductTypes(result.data);
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się załadować wszystkich danych');
		}
	};

	const fetchProducts = async (user: User) => {
		try {
			const result = await API(user).products.get({});

			setProducts(result.data);
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się załadować wszystkich danych');
		}
	};

	const productGroup = useMemo(() => {
		if (!productGroups || !productGroupId) {
			return undefined;
		}

		const parsed = parseInt(productGroupId, 10);
		if (isNaN(parsed)) {
			return undefined;
		}

		return productGroups.find((productGroup) => productGroup.id === parsed);
	}, [productGroups, productGroupId]);

	const productType = useMemo(() => {
		if (!productTypes || !productTypeId) {
			return undefined;
		}

		const parsed = parseInt(productTypeId, 10);
		if (isNaN(parsed)) {
			return undefined;
		}

		return productTypes.find((productType) => productType.id === parsed);
	}, [productTypes, productTypeId]);

	const product = useMemo(() => {
		if (!products || !productId) {
			return undefined;
		}

		const parsed = parseInt(productId, 10);
		if (isNaN(parsed)) {
			return undefined;
		}

		return products.find((product) => product.id === parsed);
	}, [products, productId]);

	return {
		productGroups,
		productGroup,
		productTypes: productTypes?.filter((productType) => productType.groupId === productGroup?.id),
		productType,
		products: products?.filter((product) => product.typeId === productType?.id),
		product,
	};
};
