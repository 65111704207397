import { useEffect, useState } from 'react';
import { API, User, useCurrentUser } from 'shared';
import { message } from 'antd';

export const useUsers = () => {
	const currentUser = useCurrentUser();
	const [users, setUsers] = useState<User[]>();

	useEffect(() => {
		fetchData();
	}, [currentUser]);

	const fetchData = async () => {
		if (!currentUser) {
			return;
		}

		try {
			const response = await API(currentUser).users.get();

			setUsers(response.data);
		} catch (error) {
			message.error('Nie udało się pobrać danych');

			console.trace(error);
		}
	};

	return {
		users,
		reload: fetchData,
	};
};
