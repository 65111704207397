import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const CurrentUserContext = createContext<User | null>(null);

export const useCurrentUser = () => useContext(CurrentUserContext);

interface Props {
	children: ReactNode;
}

export const CurrentUserProvider: React.FC<Props> = ({ children }: Props) => {
	const navigate = useNavigate();
	const [user, setUser] = useState<User | null>(null);

	useEffect(() => {
		const auth = getAuth();

		return onAuthStateChanged(auth, (user) => {
			setUser(user);

			if (!user) {
				navigate('/');
			}
		});
	}, []);

	return <CurrentUserContext.Provider value={user}>{children}</CurrentUserContext.Provider>;
};
