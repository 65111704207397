export enum Entitlement {
	// Product group
	addProductGroup = 'add_product_group',
	editProductGroup = 'edit_product_group',
	deleteProductGroup = 'delete_product_group',
	// Product type
	addProductType = 'add_product_type',
	editProductType = 'edit_product_type',
	deleteProductType = 'delete_product_type',
	// Product
	addProduct = 'add_product',
	editProduct = 'edit_product',
	deleteProduct = 'delete_product',
	// Document
	addDocument = 'add_document',
	editDocument = 'edit_document',
	deleteDocument = 'delete_document',
	// Document group
	addDocumentGroup = 'add_document_group',
	editDocumentGroup = 'edit_document_group',
	deleteDocumentGroup = 'delete_document_group',
	// Profile
	listProfiles = 'list_profiles',
	deleteProfile = 'delete_profile',
	// User
	listEntitlements = 'list_entitlements',
	patchEntitlements = 'patch_entitlements',
	// Others
	accessPanel = 'access_panel',
	accessAllDocuments = 'access_all_documents',
}
