export interface Document {
	id: number;
	name: string;
	description: string;
	file: string;
	order: number;
	productId: number;
	documentGroupId: number;
	createdAt: string;
	createdBy: string;
}
