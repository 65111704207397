import React from 'react';
import { Upload } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import { styled } from 'styled-components';

const Dragger = styled(Upload.Dragger)`
	border-radius: 4px !important;
	overflow: hidden;
`;

interface Props {
	acceptedFileTypes: string;
	value: File | null;
	onChange: (file: File) => void;
}

export const FileInput: React.FC<Props> = ({ acceptedFileTypes, value, onChange }: Props) => {
	const buildPreview = () => {
		if (value) {
			return (
				<div id="file flex flex-ac">
					<p>{value.name}</p>
				</div>
			);
		}

		return (
			<>
				<p className="ant-upload-drag-icon">
					<FileImageOutlined />
				</p>
				<p className="ant-upload-text">Kliknij albo przeciągnij plik tutaj</p>
				<p className="ant-upload-hint">Wgraj plik pdf oferty</p>
			</>
		);
	};

	return (
		<Dragger
			multiple={false}
			beforeUpload={(file) => {
				onChange(file);

				return false;
			}}
			showUploadList={false}
			accept={acceptedFileTypes}
		>
			{buildPreview()}
		</Dragger>
	);
};
