import React, { useState } from 'react';
import { Card } from 'antd';
import { gray, red } from '@ant-design/colors';
import { DeleteOutlined } from '@ant-design/icons';
import { Document } from 'shared';
import { styled } from 'styled-components';
import { PDF } from 'assets/index';

import { useDocumentCard } from './use_document_card';
import { DraggableProvided } from 'react-beautiful-dnd';

const StyledCard = styled(Card)`
	display: flex;
	flex-direction: row;
	padding: 16px;
	gap: 16px;

	> .ant-card-cover {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
		box-sizing: content-box;
	}

	> .ant-card-body {
		flex: 1;
		padding: 0;
	}

	> .ant-card-actions {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-top: none;
	}

	:hover {
		cursor: pointer;
	}
`;

interface Props {
	document: Document;
	refetch: () => Promise<void>;
	provided: DraggableProvided;
}

export const DocumentCard: React.FC<Props> = ({ document, refetch, provided }) => {
	const { openDocument, deleteDocument } = useDocumentCard(document);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const _onClickDelete = async () => {
		if (deleteLoading) {
			return;
		}

		setDeleteLoading(true);

		await deleteDocument();
		await refetch();

		setDeleteLoading(false);
	};

	return (
		<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
			<StyledCard
				cover={<PDF />}
				onClick={openDocument}
				actions={[
					<DeleteOutlined
						style={{ color: deleteLoading ? gray[5] : red[5] }}
						onClick={(event) => {
							event?.preventDefault();
							event?.stopPropagation();

							_onClickDelete();
						}}
					/>,
				]}
			>
				<Card.Meta title={document.name} description={document.description} />
			</StyledCard>
		</div>
	);
};
