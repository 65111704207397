import { message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API, useCurrentUser, ProductTypeDetails, EditProductTypeDto, Product } from 'shared';

export const useProductTypeDetails = () => {
	const { productTypeId: rawId } = useParams();
	const navigate = useNavigate();
	const id = useMemo(() => {
		if (!rawId) {
			return null;
		}

		const parsed = parseInt(rawId);
		if (isNaN(parsed)) {
			message.error('Nie znaleziono typu produktów.');

			return null;
		}

		return parsed;
	}, [rawId]);

	const user = useCurrentUser();
	const [productTypeDetails, setProductTypeDetails] = useState<ProductTypeDetails | null>(null);

	useEffect(() => {
		setProductTypeDetails(null);

		fetchData();
	}, [user, id]);

	const fetchData = async () => {
		if (!user || !id) {
			return;
		}

		try {
			const response = await API(user).productType(id).get();

			setProductTypeDetails(response.data);
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się pobrać danych.');
		}
	};

	const edit = async (dto: EditProductTypeDto) => {
		if (!user || !id) {
			return;
		}

		try {
			await API(user).productType(id).edit(dto);

			await fetchData();
		} catch (error) {
			console.trace(error);

			message.error('Edycja nie powiodła się.');
		}
	};

	const removeProductType = async () => {
		if (!user || !id) {
			return;
		}

		try {
			await API(user).productType(id).delete();
			navigate(`/panel/product_groups/${productTypeDetails?.groupId}`, { replace: true });
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się usunąć typu produktów');
		}
	};

	const changeOrder = async (products: Product[]) => {
		if (!user || !productTypeDetails) {
			message.error('Nie udało się zmienić kolejności.');

			return;
		}

		try {
			await API(user).products.editOrder(
				productTypeDetails.groupId,
				productTypeDetails.id,
				products.map((product) => product.id)
			);

			await fetchData();
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się zmienić kolejności.');
		}
	};

	return {
		productTypeDetails,
		removeProductType,
		edit,
		changeOrder,
	};
};
