import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DraggableProvided } from 'react-beautiful-dnd';
import { CardWithThumbnail, ProductType } from 'shared';

import { useProductTypeCard } from './use_product_type_card';

interface Props {
	productType: ProductType;
	provided: DraggableProvided;
}

export const ProductTypeCard: React.FC<Props> = ({ productType, provided }: Props) => {
	const navigate = useNavigate();

	const { loadFullCover } = useProductTypeCard(productType);

	return (
		<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
			<CardWithThumbnail
				thumbnail={productType.coverThumbnail}
				title={productType.name}
				description=""
				loadCover={loadFullCover}
				onClick={() => navigate(`/panel/product_groups/${productType.groupId}/product_types/${productType.id}`)}
			/>
		</div>
	);
};
