import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/react';
import { initializeApp } from 'firebase/app';
import { getFirebaseConfig } from './firebase_config';
import { App } from './modules/app';
import moment from 'moment';
import 'moment/locale/pl';

moment.locale('pl');

Sentry.init({
	dsn: 'https://9208d8a66b6de868c58c0ff949ca12c6@o4507250139529216.ingest.de.sentry.io/4507250144247888',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			networkCaptureBodies: true,
		}),
	],
	sampleRate: 1.0,
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 1.0,
	replaysOnErrorSampleRate: 1.0,
	environment: process.env['NODE_ENV'] ?? 'development',
	release: process.env['RELEASE_VERSION'] ?? 'development',
});

const config = getFirebaseConfig();
initializeApp(config);

render(<App />, document.getElementById('app'));
