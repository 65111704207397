import { message } from 'antd';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API, useCurrentUser } from 'shared';

export const useAddProductType = () => {
	const user = useCurrentUser();
	const navigate = useNavigate();

	const { productGroupId: rawId } = useParams();
	const productGroupId = useMemo(() => {
		if (!rawId) {
			return null;
		}

		const parsed = parseInt(rawId);
		if (isNaN(parsed)) {
			message.error('Nie znaleziono grupy produktów.');

			return null;
		}

		return parsed;
	}, [rawId]);

	const addProductType = async ({ name, description, cover }: { name: string; description: string; cover: File }) => {
		if (!user) {
			return;
		}

		if (!productGroupId) {
			return;
		}

		const coverExtension = cover.type.split('/')[1];
		if (!coverExtension) {
			message.error(`Nie udało się pobrać rozszerzenia okładki (${cover.type})`);

			return;
		}

		const coverContent = await cover.arrayBuffer();
		const coverBuffer = Buffer.from(coverContent);

		try {
			const response = await API(user).productTypes.add({
				name,
				description,
				cover: coverBuffer.toString('base64'),
				coverExtension,
				groupId: productGroupId,
			});

			message.success(`Dodano grupę produktów ${response.data.name}`);

			navigate(`/panel/product_groups/${productGroupId}/product_types/${response.data.id}`);
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się dodać grupy produktów');
		}
	};

	return { addProductType };
};
