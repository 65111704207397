export interface Product {
	id: number;
	name: string;
	shortDescription: string;
	description: string;
	pictogram: string;
	coverThumbnail: string;
	cover: string;
	order: number;
	groupId: number;
	typeId: number;
	createdAt: string;
	createdBy: string;
}
