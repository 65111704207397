import { message } from 'antd';
import { getAuth } from 'firebase/auth';

export const usePanel = () => {
	const logout = async () => {
		try {
			const auth = getAuth();
			await auth.signOut();
		} catch (error) {
			console.trace(error);

			message.error('Wystąpił błąd podczas wylogowywania');
		}
	};

	return {
		logout,
	};
};
