import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { API, useCurrentUser } from 'shared';

export const useAddProductGroup = () => {
	const user = useCurrentUser();
	const navigate = useNavigate();

	const addProductGroup = async ({ name, cover }: { name: string; cover: File }) => {
		if (!user) {
			return;
		}

		const coverExtension = cover.type.split('/')[1];
		if (!coverExtension) {
			message.error(`Nie udało się pobrać rozszerzenia okładki (${cover.type})`);

			return;
		}

		const coverContent = await cover.arrayBuffer();
		const coverBuffer = Buffer.from(coverContent);

		try {
			const response = await API(user).productGroups.add({
				name,
				cover: coverBuffer.toString('base64'),
				coverExtension,
			});

			message.success(`Dodano grupę produktów ${response.data.name}`);

			navigate(`/panel/product_groups/${response.data.id}`);
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się dodać grupy produktów');
		}
	};

	return { addProductGroup };
};
