import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Login } from './pages';
import {
	AddProduct,
	AddProductGroup,
	AddProductType,
	Panel,
	ProductDetails,
	ProductGroupDetails,
	ProductGroups,
	ProductTypeDetails,
	Users,
} from '../panel/index';
import { AddDocument } from 'modules/panel/pages/add_document/add_document';

export const AppRoutes: React.FC = () => {
	const navigate = useNavigate();

	// const [panelModule, setPanelModule] = useState<typeof import('../panel/index')>();

	// const getPanelRoutes = () => {
	// 	if (!panelModule) {
	// 		return <Route path="/panel/*" element={<ModuleLoading load={loadPanelModule} />} />;
	// 	}

	// 	const { AddOffer, Panel, Products, ProductDetails, Users } = panelModule;

	// 	return (
	// 		<Route path="/panel" element={panelModule ? <Panel /> : <div />}>
	// 			{/* <Route path="" element={<Navigate to="/panel/products" replace />} /> */}
	// 			<Route path="products" element={<Outlet />}>
	// 				<Route path="" element={<Products />} />
	// 				<Route path=":id" element={<ProductDetails />} />
	// 				<Route path="add" element={<AddOffer />} />
	// 			</Route>
	// 			<Route path="users" element={<Users />} />
	// 		</Route>
	// 	);
	// };

	const loadPanelModule = async () => {
		// 	const panel = await import('modules/panel');

		// 	setPanelModule(panel);

		navigate('/panel', { replace: true });
	};

	return (
		<Routes>
			<Route path="/" element={<Login onLogin={loadPanelModule} />} />
			<Route path="/logout" element={<Login onLogin={loadPanelModule} />} />
			{/* {getPanelRoutes()} */}
			<Route path="/panel" element={<Panel />}>
				<Route path="" element={<Navigate to="/panel/product_groups" replace />} />

				<Route path="product_groups" element={<ProductGroups />} />

				<Route path="product_groups/:productGroupId" element={<ProductGroupDetails />} />
				<Route path="product_groups/add" element={<AddProductGroup />} />

				<Route path="product_groups/:productGroupId/product_types/:productTypeId" element={<ProductTypeDetails />} />
				<Route path="product_groups/:productGroupId/product_types/add" element={<AddProductType />} />

				<Route
					path="product_groups/:productGroupId/product_types/:productTypeId/products/:productId"
					element={<ProductDetails />}
				/>
				<Route
					path="product_groups/:productGroupId/product_types/:productTypeId/products/add"
					element={<AddProduct />}
				/>

				<Route
					path="product_groups/:productGroupId/product_types/:productTypeId/products/:productId/add-document"
					element={<AddDocument />}
				/>

				<Route path="users" element={<Users />} />
			</Route>
			<Route path="*" element={<h1>Not found</h1>} />
		</Routes>
	);
};
