import { message } from 'antd';
import { useEffect, useState } from 'react';
import { API, useCurrentUser, ProductGroup } from 'shared';

export const useProductGroups = () => {
	const user = useCurrentUser();
	const [productGroups, setProductGroups] = useState<ProductGroup[] | null>(null);

	useEffect(() => {
		fetchData();
	}, [user]);

	const fetchData = async () => {
		if (!user) {
			return;
		}

		try {
			const response = await API(user).productGroups.get();

			setProductGroups(response.data);
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się pobrać danych.');
		}
	};

	const changeOrder = async (productGroups: ProductGroup[]) => {
		if (!user) {
			message.error('Nie udało się zmienić kolejności.');

			return;
		}

		try {
			await API(user).productGroups.editOrder(productGroups.map((group) => group.id));

			await fetchData();
		} catch (error) {
			console.trace(error);

			message.error('Nie udało się zmienić kolejności.');
		}
	};

	return {
		productGroups,
		changeOrder,
		refetch: fetchData,
	};
};
