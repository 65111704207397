import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DraggableProvided } from 'react-beautiful-dnd';
import { CardWithThumbnail, Product } from 'shared';

import { useProductCard } from './use_product_card';

interface Props {
	product: Product;
	provided: DraggableProvided;
}

export const ProductCard: React.FC<Props> = ({ product, provided }) => {
	const navigate = useNavigate();

	const { loadFullCover } = useProductCard(product);

	return (
		<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
			<CardWithThumbnail
				thumbnail={product.coverThumbnail}
				title={product.name}
				description={product.description}
				loadCover={loadFullCover}
				onClick={() =>
					navigate(`/panel/product_groups/${product.groupId}/product_types/${product.typeId}/products/${product.id}`)
				}
			/>
		</div>
	);
};
