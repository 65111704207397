import React from 'react';
import { Tooltip } from 'antd';
import { blue, gray } from '@ant-design/colors';
import { styled } from 'styled-components';

const Wrapper = styled.div<{ color: string; backgroundColor: string }>`
	&.disabled {
		> span {
			pointer-events: none;
			cursor: not-allowed;

			&:hover {
				box-shadow: none;
			}

			> * {
				color: ${() => gray[5]};
			}
		}
	}

	> span {
		padding: 8px;
		border-radius: 4px;
		background: ${({ backgroundColor }) => backgroundColor};
		cursor: pointer;
		transition: 250ms ease-in-out;

		&:hover {
			box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
		}

		> * {
			color: ${({ color }) => color};
			font-size: 24px;
		}
	}
`;

interface Props {
	className?: string;
	children: React.ReactNode;
	color?: string;
	backgroundColor?: string;
	onClick?: () => void;
	tooltip?: string | undefined;
	disabled?: boolean;
}

export const IconButton: React.FC<Props> = ({
	className,
	children,
	color = blue[5]!,
	backgroundColor = '#fff',
	onClick,
	tooltip,
	disabled = false,
}) => {
	return (
		<Wrapper
			className={`${className ?? ''} ${disabled ? 'disabled' : ''}`}
			color={color}
			backgroundColor={backgroundColor}
			onClick={onClick}
		>
			<Tooltip popupVisible={!!tooltip} title={tooltip}>
				{children}
			</Tooltip>
		</Wrapper>
	);
};
