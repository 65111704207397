const firebaseConfigDev = {
	apiKey: 'AIzaSyCgUgFMEH1T3C9mKRAg2oZifKU94b1Vixo',
	authDomain: 'decokoncept-dev.firebaseapp.com',
	projectId: 'decokoncept-dev',
	storageBucket: 'decokoncept-dev.appspot.com',
	messagingSenderId: '339263550387',
	appId: '1:339263550387:web:bf60d59d8d322972e4d1c6',
	measurementId: 'G-62MWHX1CQ7',
};

const firebaseConfigProd = {
	apiKey: 'AIzaSyCzV9ppNVDtqxjbcw_7IXinZB0V7-t2U1k',
	authDomain: 'decokoncept-prod.firebaseapp.com',
	projectId: 'decokoncept-prod',
	storageBucket: 'decokoncept-prod.appspot.com',
	messagingSenderId: '1031270985138',
	appId: '1:1031270985138:web:22dac0db85a657c54408ab',
	measurementId: 'G-9E8KN4PELB',
};

export const getFirebaseConfig = () => {
	const env = process.env['NODE_ENV'];
	switch (env) {
		case 'development':
			return firebaseConfigDev;

		case 'production':
			return firebaseConfigProd;
	}

	throw new Error(`Firebase has not been configured for ${env} environment.`);
};
