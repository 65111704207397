import { message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API, AddDocumentDto, DocumentGroup, useCurrentUser } from 'shared';
import { DocumentDto } from '../add_product/components/add_document_form';

export const useAddDocument = () => {
	const user = useCurrentUser();
	const navigate = useNavigate();
	const [documentGroups, setDocumentGroups] = useState<DocumentGroup[] | null>(null);

	const { productId: rawId } = useParams();
	const productId = useMemo(() => {
		if (!rawId) {
			return null;
		}

		const parsed = parseInt(rawId);
		if (isNaN(parsed)) {
			message.error('Nie znaleziono produktu');

			return null;
		}

		return parsed;
	}, [rawId]);

	useEffect(() => {
		fetchData();
	}, [user]);

	const fetchData = async () => {
		if (!user) {
			return;
		}

		try {
			const response = await API(user).documentGroups.get();

			setDocumentGroups(response.data);
		} catch (e) {
			console.trace(e);

			message.error('Nie udało się pobrać danych.');
		}
	};

	const addDocument = async ({ document }: { document: DocumentDto }) => {
		if (!user) {
			return;
		}

		if (!productId) {
			return;
		}

		if (!document.file || !document.groupId || !document.name || !document.description) {
			return;
		}

		const docFileExtension = document.file.type.split('/')[1];
		if (!docFileExtension) {
			message.error(`Incorrect file type ${document.file.type}`);

			return;
		}

		const docFileArrayBuffer = await document.file.arrayBuffer();
		const docFileBase64 = Buffer.from(docFileArrayBuffer).toString('base64');

		const convertedDocument: AddDocumentDto = {
			name: document.name,
			description: document.description,
			file: docFileBase64,
			fileExtension: docFileExtension,
			documentGroupId: document.groupId,
		};

		try {
			await API(user).product(productId).documents.add(convertedDocument);

			navigate(-1);
		} catch (e) {
			console.trace(e);
			message.error('Nie udało się dodać dokumentu');
		}
	};

	return { documentGroups, addDocument };
};
