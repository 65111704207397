export const readFile = async (file: File) => {
	const extension = file.type.split('/')[1];
	if (!extension) {
		throw Error(`Bad extension (${file.type})`);
	}

	const content = await file.arrayBuffer();
	const buffer = Buffer.from(content);

	return {
		extension,
		buffer,
	};
};
