import { Entitlement } from './entitlement';

export interface User {
	email?: string;
	emailVerified: boolean;
	userId: string;
	name: string;
	surname: string;
	phoneNumber: string;
	companyNumber: string;
	createdAt: Date;
	documentGroupIds: number[];
	entitlements: Entitlement[];
}
