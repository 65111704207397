import axios from 'axios';
import auth from '@firebase/auth';
import * as sentry from '@sentry/react';
import {
	Document,
	DocumentGroup,
	Entitlement,
	Product,
	ProductDetails,
	ProductGroup,
	ProductGroupDetails,
	ProductType,
	ProductTypeDetails,
	User,
} from 'shared';

export interface AddProductGroupDto {
	name: string;
	cover: string;
	coverExtension: string;
}
export interface EditProductGroupDto {
	name?: string | undefined;
	cover?: string | undefined;
	coverExtension?: string | undefined;
	isHidden?: boolean | undefined;
}
export interface EditProductTypeDto {
	name?: string | undefined;
	description?: string | undefined;
	cover?: string | undefined;
	coverExtension?: string | undefined;
}

export interface EditProductDto {
	name?: string | undefined;
	shortDescription?: string | undefined;
	description?: string | undefined;
	pictogram?: string | undefined;
	cover?: string | undefined;
	coverExtension?: string | undefined;
}

export interface AddProductTypeDto {
	name: string;
	description: string;
	cover: string;
	coverExtension: string;
	groupId: number;
}

export interface AddProductDto {
	name: string;
	shortDescription: string;
	description: string;
	pictogram: string;
	cover: string;
	coverExtension: string;
	typeId: number;
	documents: {
		name: string;
		description: string;
		file: string;
		fileExtension: string;
		documentGroupId: number;
	}[];
}

export interface AddDocumentDto {
	name: string;
	description: string;
	file: string;
	fileExtension: string;
	documentGroupId: number;
}

export const SPECIAL_DOCUMENT_GROUP_ID = 3;

export const API = (user: auth.User) => {
	const url = process.env['API']!;

	const client = axios.create({
		baseURL: url,
		paramsSerializer: {
			// No brackets in query params
			indexes: null,
		},
	});

	client.interceptors.response.use(
		(response) => response,
		(error) => {
			sentry.captureException(error, {
				extra: {
					requestData: error.request?.data,
					responseData: error.response?.data,
				},
			});

			return Promise.reject(error);
		}
	);

	return {
		users: {
			get: async () => {
				const token = await user.getIdToken();

				return client<User[]>('/profiles', {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
			},
		},

		user: (userId: string) => ({
			entitlements: {
				get: async () => {
					const token = await user.getIdToken();

					return client<Entitlement[]>(`/users/${userId}/entitlements`, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`,
						},
					});
				},

				update: async (entitlements: Entitlement[]) => {
					const token = await user.getIdToken();

					return client<void>(`/users/${userId}/entitlements`, {
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`,
						},
						data: entitlements,
					});
				},
			},

			documentGroups: {
				get: async () => {
					const token = await user.getIdToken();

					return client<number[]>(`/users/${userId}/document_groups`, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`,
						},
					});
				},

				change: async (documentGroups: number[]) => {
					const token = await user.getIdToken();

					return client<number[]>(`/users/${userId}/document_groups`, {
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`,
						},
						data: documentGroups,
					});
				},
			},
		}),

		productGroups: {
			get: async () => {
				const token = await user.getIdToken();

				return client<ProductGroup[]>('/product_groups', {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
			},

			add: async (dto: AddProductGroupDto) => {
				const token = await user.getIdToken();

				return client<ProductGroup>('/product_groups', {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					data: dto,
				});
			},

			editOrder: async (ids: number[]) => {
				const token = await user.getIdToken();

				return client<ProductGroup[]>('/product_groups', {
					method: 'PATCH',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					data: {
						order: ids,
					},
				});
			},
		},

		productGroup: (productGroupId: number) => ({
			get: async () => {
				const token = await user.getIdToken();

				return client<ProductGroupDetails>(`/product_groups/${productGroupId}`, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
			},

			getCover: async () => {
				const token = await user.getIdToken();

				return client<ArrayBuffer>(`/product_groups/${productGroupId}/cover`, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					responseType: 'arraybuffer',
				});
			},

			edit: async (dto: EditProductGroupDto) => {
				const token = await user.getIdToken();

				return client<ProductGroupDetails>(`/product_groups/${productGroupId}`, {
					method: 'PATCH',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					data: dto,
				});
			},

			delete: async () => {
				const token = await user.getIdToken();

				return client<ProductGroupDetails>(`/product_groups/${productGroupId}`, {
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
			},
		}),

		productTypes: {
			get: async ({ groups }: { groups?: number[] }) => {
				const token = await user.getIdToken();

				return client<ProductType[]>('/product_types', {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						group: groups,
					},
				});
			},

			add: async (dto: AddProductTypeDto) => {
				const token = await user.getIdToken();

				return client<ProductGroup>('/product_types', {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					data: dto,
				});
			},

			editOrder: async (productGroupId: number, ids: number[]) => {
				const token = await user.getIdToken();

				return client<ProductGroup[]>(`/product_groups/${productGroupId}/product_types`, {
					method: 'PATCH',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					data: {
						order: ids,
					},
				});
			},
		},

		productType: (productTypeId: number) => ({
			get: async () => {
				const token = await user.getIdToken();

				return client<ProductTypeDetails>(`/product_types/${productTypeId}`, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
			},

			getCover: async () => {
				const token = await user.getIdToken();

				return client<ArrayBuffer>(`/product_types/${productTypeId}/cover`, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					responseType: 'arraybuffer',
				});
			},

			edit: async (dto: EditProductTypeDto) => {
				const token = await user.getIdToken();

				return client<ProductGroupDetails>(`/product_types/${productTypeId}`, {
					method: 'PATCH',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					data: dto,
				});
			},

			delete: async () => {
				const token = await user.getIdToken();

				return client<ProductGroupDetails>(`/product_types/${productTypeId}`, {
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
			},
		}),

		products: {
			get: async ({ types }: { types?: number[] }) => {
				const token = await user.getIdToken();

				return client<Product[]>('/products', {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						type: types,
					},
				});
			},

			add: async (dto: AddProductDto) => {
				const token = await user.getIdToken();

				return client<Product>('/products', {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					data: dto,
				});
			},

			editOrder: async (productGroupId: number, productTypeId: number, ids: number[]) => {
				const token = await user.getIdToken();

				return client<ProductGroup[]>(`/product_groups/${productGroupId}/product_types/${productTypeId}/products`, {
					method: 'PATCH',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					data: {
						order: ids,
					},
				});
			},
		},

		product: (productId: number) => ({
			get: async () => {
				const token = await user.getIdToken();

				return client<ProductDetails>(`/products/${productId}`, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
			},

			getCover: async () => {
				const token = await user.getIdToken();

				return client<ArrayBuffer>(`/products/${productId}/cover`, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					responseType: 'arraybuffer',
				});
			},

			edit: async (dto: EditProductDto) => {
				const token = await user.getIdToken();

				return client<ProductGroupDetails>(`/products/${productId}`, {
					method: 'PATCH',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					data: dto,
				});
			},

			delete: async () => {
				const token = await user.getIdToken();

				return client<void>(`/products/${productId}`, {
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
			},

			document: (documentId: number) => ({
				getFile: async () => {
					const token = await user.getIdToken();

					return client<Blob>(`/products/${productId}/documents/${documentId}/file`, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`,
						},
						responseType: 'blob',
					});
				},

				delete: async () => {
					const token = await user.getIdToken();

					return client<void>(`/products/${productId}/documents/${documentId}`, {
						method: 'DELETE',
						headers: {
							Authorization: `Bearer ${token}`,
						},
					});
				},
			}),

			documents: {
				add: async (dto: AddDocumentDto) => {
					const token = await user.getIdToken();

					return client<void>(`/products/${productId}/documents`, {
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`,
						},
						data: dto,
					});
				},

				editOrder: async (productGroupId: number, productTypeId: number, ids: number[]) => {
					const token = await user.getIdToken();

					return client<Document[]>(
						`/product_groups/${productGroupId}/product_types/${productTypeId}/products/${productId}/documents`,
						{
							method: 'PATCH',
							headers: {
								Authorization: `Bearer ${token}`,
							},
							data: {
								order: ids,
							},
						}
					);
				},
			},
		}),

		documentGroups: {
			get: async () => {
				const token = await user.getIdToken();

				return client<DocumentGroup[]>('/document_groups', {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
			},

			add: async (name: string) => {
				const token = await user.getIdToken();

				return client<DocumentGroup>('/document_groups', {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					data: {
						name,
					},
				});
			},
		},

		documentGroup: (documentGroupId: number) => ({
			delete: async () => {
				const token = await user.getIdToken();

				return client<void>(`/document_groups/${documentGroupId}`, {
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
			},
		}),
	};
};
