import { API, Product, useCurrentUser } from 'shared';

export const useProductCard = (product: Product) => {
	const user = useCurrentUser();

	const loadFullCover = async () => {
		if (!user) {
			return null;
		}

		try {
			const response = await API(user).product(product.id).getCover();

			return Buffer.from(response.data).toString('base64');
		} catch (e) {
			console.trace(e);

			return null;
		}
	};

	return {
		loadFullCover,
	};
};
