import React, { useState } from 'react';
import { Image, Spin, Upload } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { styled } from 'styled-components';
import { IconButton } from './icon_button';

const Wrapper = styled.div`
	position: relative;

	> .ant-image {
		width: 100%;
		height: 100%;

		> img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	> span {
		position: absolute;
		bottom: 4px;
		right: 4px;
	}

	> .loading-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.75);
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 250ms ease-in-out;

		&.loading {
			opacity: 1;
			visibility: visible;
		}

		&.not-loading {
			opacity: 0;
			visibility: hidden;
		}
	}
`;

interface Props {
	className?: string;
	src: string;
	alt: string;
	onImageChange: (file: File) => Promise<void>;
}

export const EditableImage: React.FC<Props> = ({ className, src, alt, onImageChange }) => {
	const [loading, setLoading] = useState(false);

	const onChange = async (file: File) => {
		setLoading(true);

		await onImageChange(file);

		setLoading(false);
	};

	return (
		<Wrapper className={className}>
			<Image src={src} alt={alt} />
			<Upload
				accept="image/*"
				fileList={[]}
				beforeUpload={(file) => {
					onChange(file);
					return false;
				}}
			>
				<IconButton tooltip="Edytuj zdjęcie">
					<EditOutlined />
				</IconButton>
			</Upload>
			<div className={`loading-overlay ${loading ? 'loading' : 'not-loading'}`}>
				<Spin />
			</div>
		</Wrapper>
	);
};
